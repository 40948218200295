//circle

.#{$prefix}spinner-circle {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    border: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1.25s infinite linear;
    border-top-color: currentColor;

    &::before,
    &::after {
        left: -2px;
        top: -2px;
        display: none;
        position: absolute;
        content: '';
        width: inherit;
        height: inherit;
        border: inherit;
        border-radius: inherit;
    }

    &::before {
        display: inline-block;
        border-color: transparent;
        border-top-color: currentColor;
        animation: spin 1.5s infinite ease;
    }

    @keyframes spin {
        from {
            transform : rotate(0deg);
        }
        to {
            transform : rotate(360deg);
        }
    }

    @if(mixin-exists(hook-spinner)) {@include hook-spinner();}

    @if(mixin-exists(hook-spinner-circle)) {@include hook-spinner-circle();}
}




// cube
.#{$prefix}spinner-cubes {
    width: 3em;
    height: 3em;
    .cube {
        margin: auto;
        float: left;
        width: 33%;
        flex: 0 0 33%;
        height: 33%;
        background-color: currentColor;
        -webkit-animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
        animation: cubeGridScaleDelay 1.3s infinite ease-in-out;

        &:nth-child(1) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(6) {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(7) {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
        }
        &:nth-child(8) {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        &:nth-child(9) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(10) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
    }


    @-webkit-keyframes cubeGridScaleDelay {
        0%, 70%, 100% {
            -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
        } 35% {
              -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
          }
    }

    @keyframes cubeGridScaleDelay {
        0%, 70%, 100% {
            -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
        } 35% {
              -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
          }
    }

    @if(mixin-exists(hook-spinner)) {@include hook-spinner();}

    @if(mixin-exists(hook-spinner-cubes)) {@include hook-spinner-cubes();}

}

