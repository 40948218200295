/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// 1st: SCSS variable overrides
$global-prefix : '';


// 2nd: build import
@import '../../../../otherSrc/client/we/scss/build';

// 3rd: component hooks
@mixin hook-buttons {
    //border: 4px solid red;
}

@mixin hook-card {

}

// 4th: component import
@import '../../../../otherSrc/client/we/scss/components';

// 5th: CSS variables declaration/override
:root {
    // --global-prefix          : 'aw-';
    //--base-font-size: 3px;
    //   --base-margin   : 1.5px;
    // --container-l-width-seed : 1280px;
/*
    --c-core-inverse: #1e184f;
    --c-core-lead: #443f70;
    --c-core-lead-2: #5d5d90;
    --c-core-alt: #e9596e;
    --c-core-alt-2: #ffa6bb;

    --c-comp-lead: #e9596e;
    --c-comp-lead-2: #ffa6bb;
    --c-comp-alt: #fde021;
    --c-comp-alt-2: #ffff6e;


    --c-gray-5: #4c4c4b;
    --c-gray-4: #989897 ;
    --c-gray: #cbcbca;
    --c-gray-2: #e5e5e4;
    --c-gray-1: #f2f2f2;

    --c-success: #75c594;
    --c-warning: #fde021;
    --c-danger: #e9596e;
    */

}

// 6th: other custom CSS
*,
*::before,
*::after {
    box-sizing: border-box;
}

html, body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    background: var(--c-bg);
}
