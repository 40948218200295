

.#{$prefix}square {
    height: 100px;
    width: 100px;
    flex: 0 0 100px;

    &--large {
        height: 100px;
        width: 100px;
        flex: 0 0 100px;
    }

    &--small {
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
    }
}

.#{$prefix}sg-label {

}
