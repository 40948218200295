$badgePrefix : '.#{$prefix}badge';


#{$badgePrefix} {
    font-size: var(--font-xs);
    padding: var(--margin-xs) var(--margin-s);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--border-radius-s);
    border: 1px solid transparent;
    border-color: var(--c-lead);
    background-color: var(--c-lead);
    color: var(--c-bg);

    &--s {
        font-size: var(--font-min );
        padding: var(--margin-xs);
    }

    &--l {
        font-size: var(--font-s);
        padding: var(--margin-s) var(--margin-m);
    }

    @each $key, $var in $color-map {
        &#{$badgePrefix}-#{$key} {
            background-color: $var;
            border-color: $var;
        }
    }

    // overrides for really bad contrasts
    // bg
    &#{$badgePrefix}--bg {
        border-color: var(--c-inverse);
        color: var(--c-inverse);
    }
    // bg-4 (high contrast bg)
    &#{$badgePrefix}--bg-4 {
        color: var(--c-inverse);
    }

    // gray-2
    &#{$badgePrefix}--gray-2 {
        border-color: var(--c-gray-2);
        color: var(--c-inverse);
    }

    // gray-1
    &#{$badgePrefix}--gray-1 {
        border-color: var(--c-gray-1);
        color: var(--c-inverse);
    }

    //success-inverse
    &#{$badgePrefix}--success-inverse {
        border-color: var(--c-success);
        color: var(--c-success);
    }

    //warning-inverse
    &#{$badgePrefix}--warning-inverse {
        border-color: var(--c-warning);
        color: var(--c-warning);
    }

    // danger-inverse
    &#{$badgePrefix}--danger-inverse {
        border-color: var(--c-danger);
        color: var(--c-danger);
    }

    @if(mixin-exists(hook-badge)) {@include hook-badge();}


}
