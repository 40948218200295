$alertSelector : '.#{$prefix}alert';
#{$alertSelector} {
    position: relative;
    padding: var(--margin-m) var(--margin-l) var(--margin-l) var(--margin-l);
    background: var(--c-lead-2);
    color: var(--c-bg);
    border: 1px solid var(--c-gray-2);
    border-radius: var(--border-radius-s);
    box-shadow: var(--box-shadow-1);

    &__title {
        position: relative;
        display: block;
        font-size: var(--font-2xl);
        padding-bottom: var(--margin-m);
        margin-bottom: var(--margin-l);
       padding-top: var(--margin-s);
        font-weight: 500;
        padding-inline-end: calc(2em + var(--margin-m));

        .#{$prefix}icon {
            position: relative;
            top: 0.22em;
        }

        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            bottom: -1px;
            left: 0;
            height: 1px;
            background-color: var(--c-bg);
        }

    }

    #{$alertSelector}__content {
        margin-top: var(--margin-m);
        padding-inline-end: calc(2em + var(--margin-m));
    }

    &__title + #{$alertSelector}__content {
        margin-top: 0;
        padding-inline-end: 0;
    }

    &__title + &__close-icon,
    &__title + &__close-icon.icon {// align with "normal" content
        top: var(--margin-l);
    }
    &__close-icon, &__close-icon.icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: calc(var(--margin-m) * 2 - 0.25em); // align with "normal" content
        inset-inline-end: var(--margin-m);
        height: 1.8em;
        width: 1.8em;
        cursor: pointer;
        z-index: 2;
        @include transition(transform, 175ms, ease-in);

        &:hover {
            transform: rotate(180deg);
        }
    }

    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &--default {
        //
    }

    &--lead {
        //
    }

    &--info {
        //
    }

    &--alt {
        background: var(--c-alt);
    }

    &--gray {
        background: var(--c-gray-4);
    }

    &--success {
        background: var(--c-success);
    }

    &--warning {
        background: var(--c-warning);
    }

    &--danger, &--error {
        background: var(--c-danger);
    }


}
