
/**
 * Margin and padding classes.
 * for both padding and margin, provides: all directions, one direction, vertical, horizontal and remove aux classes, based on all size list above
 */
.#{$prefix}margin-auto {
    margin: auto!important;
}

.#{$prefix}margin-auto-vertical {
    margin-top: auto!important;
    margin-bottom: auto!important;
}

.#{$prefix}margin-auto-horizontal {
    margin-inline-end: auto!important;
    margin-inline-start: auto!important;
}

@each $key, $var in $margin-map {
    .#{$prefix}margin#{$key} {
        margin: $var!important;
    }

    .#{$prefix}margin#{$key}-inline-end,
    .#{$prefix}margin#{$key}-end{
        margin-inline-end: $var!important;
    }

    .#{$prefix}margin#{$key}-inline-start,
    .#{$prefix}margin#{$key}-start{
        margin-inline-start: $var!important;
    }

    .#{$prefix}margin#{$key}-horizonal {
        margin-inline-start : $var!important;
        margin-inline-end : $var!important;
    }

    .#{$prefix}margin#{$key}-top {
        margin-top : $var!important;
    }

    .#{$prefix}margin#{$key}-bottom {
        margin-bottom : $var!important;
    }

    .#{$prefix}margin#{$key}-vertical {
        margin-top : $var!important;
        margin-bottom : $var!important;
    }

    .#{$prefix}padding#{$key} {
        padding: $var!important;
    }

    .#{$prefix}padding#{$key}-top {
        padding-top : $var!important;
    }

    .#{$prefix}padding#{$key}-bottom {
        padding-bottom : $var!important;
    }

    .#{$prefix}padding#{$key}-inline-end {
        padding-inline-end: $var!important;
    }

    .#{$prefix}padding#{$key}-inline-start {
        padding-inline-start: $var!important;
    }

    .#{$prefix}padding#{$key}-horizontal {
        padding-inline-start: $var!important;
        padding-inline-end: $var!important;
    }

    .#{$prefix}padding#{$key}-vertical {
        padding-top : $var!important;
        padding-bottom : $var!important;
    }
}



@each $key, $var in $max-width-map {
    .#{$prefix}max-width#{$key} {
        max-width: $var!important;
    }
}
// margin remove
.#{$prefix}margin-remove {
    margin: 0!important;
}

.#{$prefix}margin-remove-vertical {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.#{$prefix}margin-remove-horizontal {
    margin-inline-end: 0!important;
    margin-inline-start: 0!important;
}

.#{$prefix}margin-inline-end-remove, .#{$prefix}margin-end-remove {
    margin-inline-end: 0!important;
}

.#{$prefix}margin-inline-start-remove {
    margin-inline-start: 0!important;
}

.#{$prefix}margin-inline-start-remove, .#{$prefix}margin-start-remove {
    margin-inline-start: 0!important;
}

.#{$prefix}margin-top-remove {
    margin-top : 0!important;
}

.#{$prefix}margin-bottom-remove {
    margin-bottom : 0!important;
}


// padding remove
.#{$prefix}padding-remove {
    padding: 0!important;
}

.#{$prefix}padding-remove-vertical {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.#{$prefix}padding-remove-horizontal {
    padding-inline-end: 0!important;
    padding-inline-start: 0!important;
}

.#{$prefix}padding-inline-end-remove, .#{$prefix}padding-end-remove {
    padding-inline-end: 0!important;
}

.#{$prefix}padding-inline-start-remove {
    padding-inline-start: 0!important;
}

.#{$prefix}padding-inline-start-remove, .#{$prefix}padding-start-remove {
    padding-inline-start: 0!important;
}

.#{$prefix}padding-top-remove {
    padding-top : 0!important;
}

.#{$prefix}padding-bottom-remove {
    padding-bottom : 0!important;
}
/**
 * Color aux classes. for each color - background and color
 */
@each $key, $var in $color-map {
    .#{$prefix}c#{$key}, .#{$prefix}color#{$key} {
        color: $var!important;
    }

    .#{$prefix}bg#{$key}, .#{$prefix}background#{$key} {
        background-color: $var!important;
    }
}

// borders
.border-none {
    border: none!important;
}
@each $key, $var in $color-map {
    .#{$prefix}border#{$key} { // todo: setup watcher so we can check results, this not working
        border: 1px solid $var;
    }
}

// box shadow
@each $key, $var in $box-shadow-map {
    .#{$prefix}shadow#{$key}, .#{$prefix}box-shadow#{$key}, .#{$prefix}z-effect#{$key} {
        box-shadow: $var!important;
    }

    .#{$prefix}shadow#{$key}-inset, .#{$prefix}box-shadow#{$key}-inset {
        box-shadow: #{$var} inset!important;
    }

}

// shadow effect
@each $key, $var in $box-shadow-map {
    .#{$prefix}z-visual#{$key} {
        box-shadow: $var;
    }

}

@each $key, $var in $z-map {
    .#{$prefix}z-level#{$key}, .#{$prefix}z#{$key} {
        z-index: $var;
    }

}
// shader
@each $key, $var in $gradient-map {
    .#{$prefix}bg-gradient#{$key} {
        background-blend-mode: normal, multiply;
        background-image: $var;
    }
}

// transition
$_transition-map : (
        '-slow'    : 450ms,
        ''         : 250ms,
        '-fast'    : 150ms,
);

@each $key, $var in $_transition-map {
    .#{$prefix}transition#{$key} {
        @include prefix( transition-property, all);
        @include prefix( transition-duration, $var!important);

        &--opacity {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}


// absolute center aux
.#{$prefix}fixed {
    position: fixed;
}

.#{$prefix}fixed-top {
    position: fixed;
    top: 0;
    z-index: var(--z-5);
}

.#{$prefix}fixed-top-strip {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: var(--z-5);
}

.#{$prefix}fixed-bottom-strip {
    position: fixed;
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: var(--z-5);
}


.#{$prefix}abs-center,.#{$prefix}absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translateX(-50%) translateY(-50%));

}

// text aux
.#{$prefix}text-center {
    text-align: center;
}

.#{$prefix}text-inline-start {
    [dir=rtl] &, .rtl & {
        text-align: right;
    }


    [dir=ltr] &, .ltr & {
        text-align: left;
    }
}

.#{$prefix}text-inline-end {
    [dir=rtl] &, .rtl & {
        text-align: left;
    }


    [dir=ltr] &, .ltr & {
        text-align: right;
    }
}

.#{$prefix}bold {
    font-weight: bold!important;
}

.#{$prefix}rtl {
    direction: rtl;
}

.#{$prefix}ltr {
    direction: ltr;
}

.#{$prefix}rtl-number {
    direction: rtl;
    unicode-bidi: bidi-override;
}

.#{$prefix}ltr-number {
    direction: ltr;
    unicode-bidi: bidi-override;
}
// flex aux
.#{$prefix}flex {
    display: flex;
}

.#{$prefix}force-flex {
    display: flex!important;
}

.#{$prefix}flex-row {
    display: flex;
    flex-direction: row;
}

.#{$prefix}flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.#{$prefix}flex-center {
    display: flex;
    justify-content: center;
}

.#{$prefix}flex-center-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.#{$prefix}flex-between {
    display: flex;
    justify-content: space-between;
}

.#{$prefix}flex-end {
    display: flex;
    justify-content: flex-end;
}

.#{$prefix}flex-start {
    display: flex;
    justify-content: flex-start;
}

.#{$prefix}flex-reverse {
    display: flex;
    flex-direction: row-reverse;

    &.flex-column {
        flex-direction: column-reverse;
    }
}

.#{$prefix}flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;


}

.#{$prefix}flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.#{$prefix}flex-middle {
    display: flex;
    align-items: center;
}

.#{$prefix}flex-top {
    display: flex;
    align-items: flex-start;
}

.#{$prefix}flex-bottom {
    display: flex;
    align-items: flex-end;
}

.#{$prefix}flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.#{$prefix}flex-no-wrap,
.#{$prefix}flex-nowrap{
    display: flex;
    flex-wrap: nowrap;
}


.#{$prefix}flex-column {
    display: flex;
    flex-direction: column;
}

.#{$prefix}flex-stretch {
    display: flex;
    align-items: stretch;
}

.#{$prefix}flex-shrink {
    flex: 0;
}

.#{$prefix}flex-break-center {
    @include whenSmallerThan($break-l) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.#{$prefix}flex-break-start {
    @include whenSmallerThan($break-l) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.#{$prefix}flex-break-column {
    @include whenSmallerThan($break-l) {
        flex-direction: column;
        align-items: center;
    }
}

.#{$prefix}flex-break-column-center {
    @include whenSmallerThan($break-l) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.#{$prefix}flex-wrap-mobile {
    @include whenMaxWidth($break-m) {
        flex-wrap: wrap;
    }
}

// responsive flex layout break points
@each $breakPointName, $breakPointVar in $break-points-map-readable {
    @include whenLargerThan($breakPointVar) {
        .#{$prefix}#{$breakPointName}\:flex {
            display: flex;
        }

        .#{$prefix}#{$breakPointName}\:flex-row {
            display: flex;
            flex-direction: row;
        }

        .#{$prefix}#{$breakPointName}\:flex-row-reverse {
            display: flex;
            flex-direction: row-reverse;
        }

        .#{$prefix}#{$breakPointName}\:flex-center {
            display: flex;
            justify-content: center;
        }

        .#{$prefix}#{$breakPointName}\:flex-center-middle {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .#{$prefix}#{$breakPointName}\:flex-between {
            display: flex;
            justify-content: space-between;
        }

        .#{$prefix}#{$breakPointName}\:flex-end {
            display: flex;
            justify-content: flex-end;
        }

        .#{$prefix}#{$breakPointName}\:flex-start {
            display: flex;
            justify-content: flex-start;
        }

        .#{$prefix}#{$breakPointName}\:flex-reverse {
            display: flex;
            flex-direction: row-reverse;

            &.flex-column {
                flex-direction: column-reverse;
            }
        }

        .#{$prefix}#{$breakPointName}\:flex-row-reverse {
            display: flex;
            flex-direction: row-reverse;


        }

        .#{$prefix}#{$breakPointName}\:flex-column-reverse {
            display: flex;
            flex-direction: column-reverse;
        }

        .#{$prefix}#{$breakPointName}\:flex-middle {
            display: flex;
            align-items: center;
        }

        .#{$prefix}#{$breakPointName}\:flex-top {
            display: flex;
            align-items: flex-start;
        }

        .#{$prefix}#{$breakPointName}\:flex-bottom {
            display: flex;
            align-items: flex-end;
        }

        .#{$prefix}#{$breakPointName}\:flex-wrap {
            display: flex;
            flex-wrap: wrap;
        }

        .#{$prefix}#{$breakPointName}\:flex-nowrap,
        .#{$prefix}#{$breakPointName}\:flex-no-wrap{
            display: flex;
            flex-wrap: nowrap;
        }

        .#{$prefix}#{$breakPointName}\:flex-column {
            display: flex;
            flex-direction: column;
        }

        .#{$prefix}#{$breakPointName}\:flex-stretch {
            display: flex;
            align-items: stretch;
        }
    }


}

// flex gaps
@each $key, $var in $margin-map {
    .#{$prefix}flex-gap#{$key},
    .#{$prefix}gap#{$key}{
        gap: $var!important;
    }
}


// width. TODO: width map with standard widths

.width-expand {
    width: 100%;
    flex: 100%;
}


.height-expand {
    height: 100%;
}
// background image etc
.#{$prefix}background-cover,
.#{$prefix}bg-cover{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.#{$prefix}background-center,
.#{$prefix}bg-center{
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
}

.#{$prefix}background-top-center,
.#{$prefix}bg-top-center {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
}

.#{$prefix}background-bottom-center,
.#{$prefix}bg-bottom-center {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
}

// misc
.#{$prefix}debug {
    border: 2px solid red;
}

.#{$prefix}border {
    border: 1px solid $color-gray;
}

.#{$prefix}min-height {
    min-height: 100px;
}

.#{$prefix}min-height-page {
    height: 100%;
    min-height: 100vh;
}

.#{$prefix}block {
    display: block;
}

.#{$prefix}inline-block {
    display: inline-block;
}

.#{$prefix}inline {
    display: inline;
}

.#{$prefix}relative {
    position: relative;
}

.#{$prefix}absolute {
    position: absolute;
}

.#{$prefix}absolute-cover {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
}

.#{$prefix}fixed {
    position: fixed;
}

.#{$prefix}static {
    position: static;
}

.#{$prefix}overflow-hidden {
    overflow: hidden;
}

.#{$prefix}overflow-visible {
    overflow: visible;
}

.#{$prefix}overflow-x-hidden {
    overflow-x: hidden;
}

.#{$prefix}overflow-y-hidden {
    overflow-y: hidden;
}

.#{$prefix}overflow-y-visible {
    overflow-y: visible;
}

.#{$prefix}overflow-x-visible {
    overflow-x: visible;
}

.#{$prefix}overflow-scroll {
    overflow: scroll;
}

.#{$prefix}overflow-y-scroll {
    overflow-y: scroll;
}

.#{$prefix}overflow-x-scroll {
    overflow-x: scroll;
}

.#{$prefix}overflow-y-auto {
    overflow-y: auto;
}

.#{$prefix}overflow-x-auto {
    overflow-x: auto;
}


.#{$prefix}debug, .#{$prefix}debug-0 {
    border: 2px solid red;
}

.#{$prefix}debug, .#{$prefix}debug-1 {
    border: 2px solid purple;
}

.#{$prefix}debug-2 {
    border: 2px solid blue;
}

.#{$prefix}debug-3 {
    border: 2px solid green;
}
// border radius
@each $key, $var in $radius-map {
    .#{$prefix}radius#{$key}, .#{$prefix}border-radius#{$key}  {
        border-radius: $var!important;
    }
}

// visibility
.hidden {
    display: none;
}
@each $key, $var in $break-points-map {
    .#{$prefix}hidden#{$key} {
        @include whenLargerThan($var) {
            display: none!important;
        }
    }

    .#{$prefix}visible#{$key} {
        @include whenSmallerThan($var) {
            display: none!important;
        }
    }
}

// visibility
@each $key, $var in $break-points-map-readable {
    .#{$prefix}#{$key}\:hidden {
        @include whenLargerThan($var) {
            display: none!important;
        }
    }

    .#{$prefix}#{$key}\:visible {
        @include whenSmallerThan($var) {
            display: none!important;
        }
    }
}


// typography

// general typography
@each $key, $var in $font-size-map {
    .#{$prefix}text#{$key} {
        font-size: $var;
    }
}

.#{$prefix}text-bold {
    font-weight: bold!important;
}

.#{$prefix}text-extra-bold, .#{$prefix}text-xbold {
    font-weight: bolder!important;
}

.#{$prefix}text-normal {
    font-weight: 400!important;
}

.#{$prefix}text-light {
    font-weight: lighter!important;
}

.#{$prefix}underline {
    text-decoration: underline;
}

.#{$prefix}no-underline {
    text-decoration: none;
}
.#{$prefix}no-decoration {
    text-decoration: none;
}


.#{$prefix}hover-effect {
    @include transition(opacity, 150ms ease);
    &:hover {
        opacity: 0.8;
    }
}
// cursor
.#{$prefix}cursor-pointer {
    cursor: pointer;
}


// img
.#{$prefix}responsive-image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}


// borders with thickness and colors - ie border-lead-thick, border-alt-thin,
// this is also valid border-thin-lead, border-alt-m
@each $thicknessKey, $thicknessValue in $border-thickness-map {
    @each $colorKey, $colorValue in $color-map {
        .#{$prefix}border#{$thicknessKey}#{$colorKey}, .#{$prefix}border#{$colorKey}#{$thicknessKey} {
            border: #{$thicknessValue} solid #{$colorValue};
        }
    }
}


// different thickness borders i.e. border-thin, border-thick
@each $thicknessKey, $thicknessValue in $border-thickness-map {
    .#{$prefix}border#{$thicknessKey}, {
        border: #{$thicknessValue} solid $color-gray;
    }
}

// differnt color borders, ie border-lead border-success
@each $colorKey, $colorValue in $color-map {
    .#{$prefix}border#{$colorKey}, {
        border: 1px solid #{$colorValue};
    }
}

//directions helper
.force-ltr {
    direction: ltr;
    unicode-bidi: embed;
}
