$modalSelector : '.#{$prefix}modal';
$modalScreenDropShadow: rgba(0,0,0,0.7)!default;
$modal-z-index : 100!default;

#{$modalSelector} {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: $modal-z-index ;
    background: $modalScreenDropShadow;
    display: none;
    align-items: center;
    justify-content: center;

    &:not(.active) {
        display: none;
    }

    &.active:last-of-type { // no multiple modals at the same time. only the last one shows
        display: flex;
    }


    // TODO: wide variation

    // todo: narrow variation

    // todo: auto scrollable
    &__inner {
        position: relative;
        flex: auto;
        width: auto;
        min-height: 100px;
        max-height: 100%;
        background: var(--c-bg);
        border: 1px solid var(--c-gray-2);
        box-shadow: var(--box-shadow-5);
        padding: 0;
        margin: var(--margin-2xl);
        min-width: 500px;
        border-radius: var(--border-radius-s);
        max-width: $screen-m-max;

        @include whenSmallerThan(m) {
            margin: var(--margin-xl) var(--margin-l);
            min-width: 100px;
            max-width: calc(100vw - 2 * var(--margin-m));
            overflow: auto; // failsafe, can always scroll the entire thing
        }

        @include whenSmallerThan(400px) {
            margin: var(--margin-m) var(--margin-xs);
            max-width : 100%
        }

        &--full {
            height: 100%;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            margin: 0;
            overflow: auto;

            @include whenSmallerThan(m) {
                margin: 0;
                overflow: auto; // failsafe, can always scroll the entire thing
            }

            @include whenSmallerThan(400px) {
                margin: 0;
            }
        }

        &--large, &--l {
            width: 65vw;
            flex: 0 0 65vw;
            max-width: 1100px;
            min-height: 75vh;
            max-height: 95vh;
            overflow: auto;

            @include whenSmallerThan(l) {
                width: 90vw;
                flex: 0 0 90vw;
            }

            @include whenSmallerThan(m) {
                margin: 0;
                overflow: auto; // failsafe, can always scroll the entire thing
            }

            @include whenSmallerThan(400px) {
                margin: 0;
            }
        }

        &--small, &--s {
            width: 350px;
            flex: 0 0 350px;
            max-width: 500px;
            min-height: 25vh;
            max-height: 45vh;
            overflow: auto;

            @include whenSmallerThan(l) {
                width: 90vw;
                flex: 0 0 90vw;
                max-height: 95vh;
            }

            @include whenSmallerThan(m) {
                margin: 0;
                overflow: auto; // failsafe, can always scroll the entire thing
            }

            @include whenSmallerThan(400px) {
                margin: 0;
            }
        }
    }

    &__icon-close {
        position: absolute;
        top: var(--margin-m);
        inset-inline-end: var(--margin-m);
        z-index: 2;
        cursor: pointer;
        @include transition(opacity);

        &:hover {
            opacity: 0.8;
        }

        @include whenSmallerThan(m) {
            top: var(--margin-s);
            inset-inline-end: var(--margin-s);
        }

    }


    &__header {
        position: relative;
        padding: var(--margin-l) var(--margin-xl) var(--margin-l) var(--margin-xl);
        border-bottom: 1px solid var(--c-gray-2);

        @include whenSmallerThan(s) {
            padding: calc(var(--margin-l) * 0.8) var(--margin-l) ;
        }

        #{$modalSelector}__title {
           padding-inline-end: var(--margin-l);
            @include whenSmallerThan(m) {
                padding-inline-end: var(--margin-l);
            }

            &--center {
                text-align: center;
                padding-inline-start: var(--margin-l);
            }
        }
    }

    #{$modalSelector}__title {
        font-size: var(--font-3xl);
        margin-bottom: 0;

        @include whenSmallerThan(s) {
            font-size: var(--font-2xl);
        }
    }

    &__body {
        padding: var(--margin-xl) var(--margin-xl) var(--margin-xl) var(--margin-xl);
        overflow: auto;
        max-height: 80vh;

        @include whenSmallerThan(s) {
            padding: var(--margin-l) ;
        }
    }
    // modal with header only
    &--has-header #{$modalSelector}__body {
        max-height: calc(100% - 80px);
    }

    // modal with footer only
    &--has-footer #{$modalSelector}__body {
        max-height: calc(100% - 80px);
    }

    // modal with header and footer
    &--has-header#{$modalSelector}--has-footer #{$modalSelector}__body {
        max-height: calc(100% - 160px);
    }


    &__footer {
        display: flex;
        gap: var(--margin-l);
        border-top: 1px solid var(--c-gray-2);
        padding:  var(--margin-l) var(--margin-xl) var(--margin-l) var(--margin-xl);
        justify-content: flex-end;
        align-items: center;

        @include whenSmallerThan(s) {
            padding: calc(var(--margin-l) * 0.8) var(--margin-l) ;
        }

        a {
            text-decoration: underline;
        }

        .button-group {
            align-items: center;
            margin: 0;
        }
    }

    @if(mixin-exists(hook-modal)) {@include hook-modal();}
}
