:root {

    // global prefix for all the classes
    --global-prefix           : '';

    // font sizes
    --base-font-size          : 1px;

    --font-min                : calc(10 * var(--base-font-size));
    --font-xs                 : calc(12 * var(--base-font-size));
    --font-s                  : calc(14 * var(--base-font-size));
    --font-m                  : calc(16 * var(--base-font-size));
    --font-l                  : calc(18 * var(--base-font-size));
    --font-xl                 : calc(20 * var(--base-font-size));
    --font-2xl                : calc(25 * var(--base-font-size));
    --font-3xl                : calc(32 * var(--base-font-size));
    --font-max                : calc(38 * var(--base-font-size));

    // margins
    --base-margin             : 1px;

    --margin-xs               : calc(2 * var(--base-margin));
    --margin-s                : calc(5 * var(--base-margin));
    --margin-m                : calc(10 * var(--base-margin));
    --margin                  : var(--margin-m);
    --margin-l                : calc(20 * var(--base-margin));
    --margin-xl               : calc(30 * var(--base-margin));
    --margin-2xl              : calc(45 * var(--base-margin));
    --margin-max              : calc(60 * var(--base-margin));

    // colors
    --c-core-lead             : #3867d6;
    --c-core-lead-2           : #2d98da;
    --c-core-alt              : #07a652;
    --c-core-alt-2            : #26de81;
    --c-core-inverse          : #222222;
    --c-core-bg-4             : #faf9f6;
    --c-core-bg-5             : #edece9;
    --c-core-bg               : #ffffff;
    --c-text                  : #2a2a2a;
    --c-comp-lead             : #8854d0;
    --c-comp-lead-2           : #a55eea;
    --c-comp-alt              : #fa8231;
    --c-comp-alt-2            : #fd9644;
    --c-gray-1                : #f2f2f2;
    --c-gray-2                : #d1d8e0;
    --c-gray                  : #a5b1c2;
    --c-gray-3                : var(--c-gray);
    --c-gray-4                : #778ca3;
    --c-gray-5                : #393946;
    --c-success               : #07a652;
    --c-success-2             : #3ad985;
    --c-success-inverse       : var(--c-bg);
    --c-warning               : #ee8735;
    --c-warning-inverse       : var(--c-bg);
    --c-danger                : #eb3b5a;
    --c-danger-inverse        : var(--c-bg);
    --c-error                 : var(--c-danger);
    --c-error-inverse         : var(--c-danger-inverse);


    // color aliases
    --c-lead                  : var(--c-core-lead);
    --c-lead-2                : var(--c-core-lead-2);
    --c-alt                   : var(--c-core-alt);
    --c-alt-2                 : var(--c-core-alt-2);
    --c-inverse               : var(--c-core-inverse);
    --c-bg-4                  : var(--c-core-bg-4);
    --c-bg-5                  : var(--c-core-bg-5);
    --c-bg                    : var(--c-core-bg);


    // barnd colors
    --c-facebook              : #4867aa;

    // box shadow

    --box-shadow-1            : 0 2px 8px rgba(0, 0, 0, .08);
    --box-shadow-2            : 0 5px 15px rgba(0, 0, 0, .08);
    --box-shadow-3            : 0 8px 22px rgba(0, 0, 0, .12);
    --box-shadow-4            : 0 14px 25px rgba(0, 0, 0, .16);
    --box-shadow-5            : 0 28px 50px rgba(0, 0, 0, .16);
    --box-shadow              : var(--box-shadow-3);


    // containers
    --container-gutter        : 20px;

    --container-xs-width-seed : 400px;
    --container-s-width-seed  : 800px;
    --container-m-width-seed  : 1124px;
    --container-l-width-seed  : 1300px;
    --container-xs            : calc(var(--container-xs-width-seed) + (2 * var(--container-gutter)));
    --container-s             : calc(var(--container-s-width-seed) + (2 * var(--container-gutter)));
    --container-m             : calc(var(--container-m-width-seed) + (2 * var(--container-gutter)));
    --container               : var(--container-m);
    --container-l             : calc(var(--container-l-width-seed) + (2 * var(--container-gutter)));

    --border-radius-sharp     : 3px;
    --border-radius-s         : var(--border-radius-sharp);
    --border-radius           : 20px;
    --border-radius-m         : var(--border-radius);
    --border-radius-l         : 35px;
    --border-radius-round     : 50%;


    --z-0                     : 0;
    --z-1                     : 1;
    --z-2                     : 2;
    --z-3                     : 3;
    --z-4                     : 4;
    --z-5                     : 5;
    --z-top-1                 : 1000;
    --z-top-2                 : 1001;
    --z-top-3                 : 1002;
    --z-top-4                 : 1003;
    --z-top-5                 : 1004;

    --width-50                : 50px;
    --width-100               : 100px;
    --width-150               : 150px;
    --width-200               : 200px;
    --width-300               : 300px;
    --width-400               : 400px;
    --width-500               : 500px;
    --width-600               : 600px;
    --width-700               : 700px;
    --width-800               : 800px;


    // --button-border-radius       : var(--border-radius);
    //--button-border-radius-round : var(--border-radius-round);
    //--button-border-radius-sharp : var(--border-radius-sharp);
}

// global prefix
$global-prefix             : 'we-' !default;
$prefix                    : $global-prefix !default;

// margin scss vars
$margin-base               : var(--base-margin) !default;
$base-margin               : $margin-base !default;
$margin-xs                 : var(--margin-xs) !default;
$margin-s                  : var(--margin-s) !default;
$margin-m                  : var(--margin-m) !default;
$margin                    : $margin-m !default;
$margin-l                  : var(--margin-l) !default;
$margin-xl                 : var(--margin-xl) !default;
$margin-2xl                : var(--margin-2xl) !default;
$margin-max                : var(--margin-max) !default;

$margin-map                : (
        '-xs' : $margin-xs,
        '-s' : $margin-s,
        '-small' : $margin-s,
        '-m' : $margin-m,
        '-medium' : $margin-m,
        '' : $margin,
        '-l' : $margin-l,
        '-large' : $margin-l,
        '-xl' : $margin-xl,
        '-2xl' : $margin-2xl,
        '-max' : $margin-max
) !default;

// widths
$width-50                  : var(--width-50);
$width-100                 : var(--width-100);
$width-150                 : var(--width-150);
$width-200                 : var(--width-200);
$width-300                 : var(--width-300);
$width-400                 : var(--width-400);
$width-500                 : var(--width-500);
$width-600                 : var(--width-600);
$width-700                 : var(--width-700);
$width-800                 : var(--width-800);

$max-width-map            : (
        '-50' : $width-50,
        '-100' :$width-100,
        '-150' :$width-150,
        '-200' :$width-200,
        '-300' :$width-300,
        '-400' :$width-400,
        '-500' :$width-500,
        '-600' :$width-600,
        '-700' :$width-700,
        '-800' :$width-800,

) !default;


$z-0                       : var(--z-0);
$z-1                       : var(--z-1);
$z-2                       : var(--z-2);
$z-3                       : var(--z-3);
$z-4                       : var(--z-4);
$z-5                       : var(--z-5);
$z-top-1                   : var(--z-top-1);
$z-top-2                   : var(--z-top-2);
$z-top-3                   : var(--z-top-3);
$z-top-4                   : var(--z-top-4);
$z-top-5                   : var(--z-top-5);

$z-map                     : (
        '-0' : $z-0,
        '-1' : $z-1,
        '-2' : $z-2,
        '-3' : $z-3,
        '-4' : $z-4,
        '-5' : $z-5,
        '-top-1' : $z-top-1,
        '-top-2' : $z-top-2,
        '-top-3' : $z-top-3,
        '-top-4' : $z-top-4,
        '-top-5' : $z-top-5
) !default;


// color scss vars
$color-core-lead           : var(--c-core-lead) !default;
$color-core-lead-2         : var(--c-core-lead-2) !default;
$color-core-alt            : var(--c-core-alt) !default;
$color-core-alt-2          : var(--c-core-alt-2) !default;
$color-core-inverse        : var(--c-core-inverse) !default;
$color-core-bg-4           : var(--c-core-bg-4) !default;
$color-core-bg-5           : var(--c-core-bg-5) !default;
$color-core-bg             : var(--c-core-bg) !default;
$color-lead                : var(--c-lead) !default;
$color-lead-2              : var(--c-lead-2) !default;
$color-alt                 : var(--c-alt) !default;
$color-alt-2               : var(--c-alt-2) !default;
$color-inverse             : var(--c-inverse) !default;
$color-bg-5                : var(--c-bg-5) !default;
$color-bg-4                : var(--c-bg-4) !default;
$color-bg                  : var(--c-bg) !default;
$color-text                : var(--c-text) !default;
$color-comp-lead           : var(--c-comp-lead) !default;
$color-comp-lead-2         : var(--c-comp-lead-2) !default;
$color-comp-alt            : var(--c-comp-alt) !default;
$color-comp-alt-2          : var(--c-comp-alt-2) !default;
$color-gray-5              : var(--c-gray-5) !default;
$color-gray-4              : var(--c-gray-4) !default;
$color-gray                : var(--c-gray) !default;
$color-gray-2              : var(--c-gray-2) !default;
$color-gray-1              : var(--c-gray-1) !default;
$color-success             : var(--c-success) !default;
$color-success-inverse     : var(--c-success-inverse) !default;
$color-warning             : var(--c-warning) !default;
$color-warning-inverse     : var(--c-warning-inverse) !default;
$color-danger              : var(--c-danger) !default;
$color-danger-inverse      : var(--c-danger-inverse) !default;
$color-error               : var(--c-error) !default;
$color-error-inverse       : var(--c-error-inverse) !default;

$color-map                 : (
        '-core-lead' : $color-core-lead,
        '-core-lead-2' : $color-core-lead-2,
        '-core-alt' : $color-core-alt,
        '-core-inverse' : $color-core-inverse,
        '-core-bg-4' : $color-core-bg-4,
        '-core-bg' : $color-core-bg,
        '-lead' : $color-lead,
        '-lead-2' : $color-lead-2,
        '-alt' : $color-alt,
        '-alt-2' : $color-alt-2,
        '-inverse' : $color-inverse,
        '-bg-5' : $color-bg-5,
        '-bg-4' : $color-bg-4,
        '-bg' : $color-bg,
        '-text' : $color-text,
        '-comp-lead' : $color-comp-lead,
        '-comp-lead-2' : $color-comp-lead-2,
        '-comp-alt' : $color-comp-alt,
        '-comp-alt-2' : $color-comp-alt-2,
        '-gray-5' : $color-gray-5,
        '-gray-4' : $color-gray-4,
        '-gray' : $color-gray,
        '-gray-2' : $color-gray-2,
        '-gray-1' : $color-gray-1,
        '-success' : $color-success,
        '-success-inverse' : $color-success-inverse,
        '-warning' : $color-warning,
        '-warning-inverse' : $color-warning-inverse,
        '-danger' : $color-danger,
        '-danger-inverse' : $color-danger-inverse,
        '-error-inverse' : $color-error-inverse,
        '-error' : $color-error,
) !default;

$grayscale-map                 : (
        '-gray-1' : $color-gray-1,
        '-gray-2' : $color-gray-2,
        '-gray'   : $color-gray,
        '-gray-3' : $color-gray,
        '-gray-5' : $color-gray-5,
        '-gray-4' : $color-gray-4,
) !default;
// box shadow scss vars


$box-shadow-1              : var(--box-shadow-1) !default;

$box-shadow-2              : var(--box-shadow-2) !default;
$box-shadow-3              : var(--box-shadow-3) !default;
$box-shadow                : var(--box-shadow) !default;
$box-shadow-4              : var(--box-shadow-4) !default;
$box-shadow-5              : var(--box-shadow-5) !default;


$box-shadow-map            : (
        '-1': $box-shadow-1,
        '-2': $box-shadow-2,
        '-3': $box-shadow-3,
        '' : $box-shadow,
        '-4': $box-shadow-4,
        '-5': $box-shadow-5,
) !default;

$gradient-1                : linear-gradient(180deg, rgba(255, 255, 255, 0) 9.65%, #ffffff 70.12%), linear-gradient(20.35deg, #ffffff 45.49%, rgba(255, 255, 255, 0) 73.38%) !default;
$gradient-5                : linear-gradient(to top, #000000, rgba(255, 255, 255, 0), #ffffff) !default;
$gradient-complex          : linear-gradient(148.01deg, #000000 -12.1%, rgba(0, 0, 0, 0) 32.56%), linear-gradient(164.53deg, rgba(0, 0, 0, 0) 45.91%, #000000 87.41%) !default;
$gradiant-diagonal         : linear-gradient(to top, #000000, rgba(0, 0, 0, 0)), linear-gradient(to top, rgba(0, 0, 0, 0), #000000) !default;

$gradient-map              : (
        '-1' : $gradient-1,
        '-5' : $gradient-5,
        '-complex' : $gradient-complex,
        '-diagonal' : $gradiant-diagonal,
) !default;


$screen-s-min              : 500px !default;
$screen-s-max              : 499px !default;
$screen-m-min              : 768px !default;
$screen-m-max              : 767px !default;
$screen-l-min              : 1025px !default;
$screen-l-max              : 1024px !default;
$screen-xl-min             : 1300px !default;
$screen-xl-max             : 20000px !default;

$break-s                   : $screen-s-max;
$break-m                   : $screen-m-max;
$break-l                   : $screen-l-max;
$break-xl                  : $screen-xl-max;

$break-points-map          : (
        '-s' : $screen-s-max,
        '-m' : $screen-m-max,
        '-l' : $screen-l-max,
        '-xl' : $screen-xl-min,
) !default;

$break-points-map-readable : (
        's' : $screen-s-max,
        'm' : $screen-m-max,
        'l' : $screen-l-max,
        'xl' : $screen-xl-min,
) !default;

$font-base-size            : var(--base-font-size) !default;
$font-min                  : var(--font-min) !default;
$font-2xs                  : var(--font-2xs) !default;
$font-xs                   : var(--font-xs) !default;
$font-s                    : var(--font-s) !default;
$font-m                    : var(--font-m) !default;
$font-l                    : var(--font-l) !default;
$font-xl                   : var(--font-xl) !default;
$font-2xl                  : var(--font-2xl) !default;
$font-3xl                  : var(--font-3xl) !default;
$font-max                  : var(--font-max) !default;

$font-size-map             : (
        '-min' : $font-min,
        '-xs' : $font-xs,
        '-s' : $font-s,
        '-m' : $font-m,
        '-l' : $font-l,
        '-xl' : $font-xl,
        '-2xl' : $font-2xl,
        '-3xl' : $font-3xl,
        '-max' : $font-max,
) !default;

$container-gutter          : var(--container-gutter) !default;

$container-xs              : var(--container-xs) !default;
$container-s               : var(--container-s) !default;
$container-m               : var(--container-m) !default;
$container                 : var(--container-m) !default;
$container-l               : var(--container-l) !default;

$container-map             : (
        '-xs' : $container-xs,
        '-s' : $container-s,
        '-m' : $container-m,
    // '' : $container-m,
        '-l' : $container-l,
) !default;

// border radius
$border-radius-sharp       : var(--border-radius-sharp) !default;
$border-radius-s           : var(--border-radius-s) !default;
$border-radius             : var(--border-radius) !default;
$border-radius-m           : var(--border-radius-m) !default;
$border-radius-l           : var(--border-radius-l) !default;
$border-radius-round       : var(--border-radius-round) !default;


$radius-map                : (
        '-sharp' : $border-radius-sharp,
        '-s' : $border-radius-sharp,
        '' : $border-radius,
        '-m' : $border-radius,
        '-l' : $border-radius-l,
        '-round' : $border-radius-round,
) !default;


$border-thickness-map                : (
        '-thin' : 1px,
        '-s' : 1px,
        '' : 3px,
        '-medium' : 3px,
        '-m' : 3px,
        '-thick' : 6px,
        '-l' : 6px,
) !default;

