@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
* {
    line-height: 1.3;
    font-family: 'Montserrat', sans-serif;
    @if(mixin-exists(hook-base-font)) {@include hook-base-font();}
}

html, body {
    color: var(--c-text);
    font-size: var(--font-m);

    .text-hero {
        font-size: calc(var(--font-max) * 1.75);
        font-weight: 500;
        margin-bottom: calc(var(--font-max) * 1.35);
        display: inline-block;
    }


    h1, .#{$prefix}h1 {
        font-size: calc(var(--font-max) * 1);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: calc(var(--font-max) * 1.15);
    }


    h2, .#{$prefix}h2 {
        font-size: calc(var(--font-max) * 0.8);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: calc(var(--font-max) * 1);
    }

    h3, .#{$prefix}h3 {
        font-size: calc(var( --font-xl) * 1.25);
        font-weight: 400;
        margin-top: 0;
        margin-bottom: calc(var( --font-xl) * 1.4);
    }

    h4, .#{$prefix}h4 {
        font-size: calc(var( --font-xl) * 1);
        font-weight: 600;
        margin-top: 0;
        margin-bottom: calc(var( --font-xl) * 1.1);
    }

    h5, .#{$prefix}h5 {
        font-size: calc(var( --font-l) * 1);
        font-weight: 600;
        margin-top: 0;
        margin-bottom: calc(var( --font-l) * 1.1);
    }


    h6, .#{$prefix}h6 {
        font-size: calc(var( --font-m) * 1);
        font-weight: 800;
        margin-top: 0;
        margin-bottom: calc(var( --font-l) * 1.1);
    }

    * + h1, * + .#{$prefix}h1,
    * + h2, * + .#{$prefix}h2,
    * + h3, * + .#{$prefix}h3,
    * + h4, * + .#{$prefix}h4,
    * + h6, * + .#{$prefix}h5,
    * + h6, * + .#{$prefix}h6 {
        margin-top: inherit;
    }

    p, .#{$prefix}p {
        font-size: var(--font-m);
        font-weight : normal;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: 0;

        + p, +.#{$prefix}p  {
            margin-top: calc(var(--font-m) * 1.4);
        }

    }

    a:not(button) {
        color: var(--c-lead);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }


    @if(mixin-exists(hook-typography)) {@include hook-typography();}
}
